import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  backlinkLabel: 'IT System Modernization',
  titleCaption: 'Our unblocking Vision-2-Reality method',
  title: 'Easily modernize your systems',
  description: '<h3>Do your business suffer from <b>outdated</b> technology, <b>slow</b> legacy systems, <b>inflexible</b> system architecture, distributed data and dependencies that limit your ability to innovate?</h3><br/><p>Our proven unblocking Vision-2-Reality method solve dependencies and innovation blockades. Get ready to tackle the digital challenges of your business now.</p>',
  viewExamples: 'View examples',
  contactTeaserTitle: '<b>Get free consultation</b> on how to modernize your systems',
  contactTeaserIntroInfo: 'Do you have any questions or like to talk about how to tackle the digital challenges of your business now? Do not hesitate contacting us. We look forward to your questions and would love to support you.{br}{br}',
  contactbtnLabel: 'Get free consultation now'
})
